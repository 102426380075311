.contact__section{
    width: 100%;
    position: relative;
    background-color: var(--background-black-color);
    margin-top: 5%;
}
.container{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.contact__container{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 2%;
}
.contact__container > h1{
    font-size: 30px;
    font-weight: 300;
    color: white;
    width: 70%;
    text-align: center;
}
.contact__container > button{
     border: none;
     outline: none;
     padding: 10px;
     border-radius: 10px;
     background-color: blue;
     color: white;
     height: 50px;
     width: 20%;
     font-size: 20px;
     cursor: pointer;
}
@media only screen and (max-width: 768px) {
    .contact__section{
        height: auto;
    }
    .container{
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .contact__container{
        display: block;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contact__container > h1{
        font-size: 20px !important;
        width: 80%;
        text-align: center;
        font-weight: bold;
    }
    .contact__container > button {
        width: 50%;
        font-size: 12px;
        height: 40px;
    }
}