.home__container{
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home__content_container{
    width: 100%;
    height: 100%;
}