.cbdcx__section {
  width: 100%;
  position: relative;
}
.our__mission_section {
  width: 100%;
  position: relative;
  margin-top: 0%;
  margin-bottom: 5%;
}
.container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.cbdcx__container {
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px;
}
.cbdcx__container > h6 {
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  color: var(--secondary-black-text);
}
.cbdcx__container > h1 {
  margin: 30px 0px;
  font-size: 60px;
}
.cbdcx__container > p {
  margin: 0px;
  width: 80%;
  font-size: 18px;
  text-align: center;
}
.grid__container {
  display: grid;
  gap: 30px;
  border-radius: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "item1 item2 item3";
  margin-top: 2%;
  width: 70%;
}
.grid__content_one {
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: -webkit-transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
  cursor: pointer;
  grid-area: item1;
  width: 100%;
  max-width: 432px;
  background-image: linear-gradient(135deg, #ff4f42 0%, #ff8e3c 100%);
  text-align: center;
  padding: 10px;
}
.grid__content_two{
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    border-radius: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: -webkit-transform .3s;
    -webkit-transition: transform .3s;
    transition: transform .3s;
    cursor: pointer;
    grid-area: item2;
    width: 100%;
    max-width: 432px;
    background-image: linear-gradient(134.98deg,#612FFF 13.72%,#D200A3 100%);
    padding: 10px;
}
.grid__color{
    color: white;
    padding-bottom: 20px;
}
.grid__content_three{
    -webkit-text-decoration: none;
    text-decoration: none;
    border-radius: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: -webkit-transform .3s;
    -webkit-transition: transform .3s;
    transition: transform .3s;
    cursor: pointer;
    grid-area: item3;
    width: 100%;
    max-width: 432px;
    background-image: linear-gradient(135deg,#D200A3 0%,#F80F6B 100%);
    text-align: center;
    padding: 10px;
}
.grid__content_one:hover{
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
    box-shadow: 0px 2px 12px rgb(0 0 0 / 12%);
}
.grid__box_content{
    width: 100%;
    padding-top: 3px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 0px;
}
.grid__box_image{
    max-width: 100%;
    position: relative;
    padding-left: 32px;
    padding-right: 32px;
}
@media only screen and (max-width: 768px) {
  .grid__container {
    display: block;
    gap: 30px;
    border-radius: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "item1 item2 item3";
    margin-top: 2%;
    width: 70%;
  }
  .grid__content_one, .grid__content_two, .grid__content_three{
    margin-bottom: 30px;
  }
  .cbdcx__container > h1 {
    margin: 30px 0px;
    font-size: 28px !important;
  }
  .cbdcx__container > p {
    font-size: 16px !important;
    width: 100%;
  }
}