.landing__section {
  width: 100%;
  height: calc(100% - 30vh);
  position: relative;
}
.globe__container {
  width: 40%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.landing__container {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px;
}
.landing__content {
  width: 80%;
}
.landing__content > h1 {
  font-size: 80px;
  color: var(--primary-black-text);
  text-align: center;
  margin: 0px;
}
.landing__content > p {
  text-align: center;
  font-size: 18px !important;
}
.landing__stats {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
}
.stats__container {
  flex: 0.2;
  height: 50px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stats__container > h3 {
  margin: 0px;
  font-size: 30px;
  text-align: center;
}
.stats__container > p {
  margin: 0px;
  font-size: 15px;
  text-align: center;
}
.counter {
  font-size: 40px !important;
  margin: 0px;
  font-weight: 500;
}
@media only screen and (max-width: 768px) {
  .landing__section {
    height: auto;
    margin-bottom: 5%;
  }
  .landing__container {
    height: auto;
  }
  .container {
    height: auto;
  }
  .landing__content {
    width: 100%;
  }
  .landing__stats {
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0%;
  }
  .stats__container {
    height: auto;
    flex-basis: 50%;
    margin: 20px 0px;
  }
  .stats__container > p {
    margin: 0px;
    font-size: 12px;
  }
  .counter {
    font-size: 20px !important;
    margin: 0px;
    font-weight: 500;
  }
  .landing__content > h1 {
    font-size: 30px !important;
    color: var(--primary-black-text);
    text-align: center;
    margin: 0px;
  }
  .landing__content > p {
    font-size: 16px !important;
  }
}
