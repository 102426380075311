.footer__section{
    width: 100%;
    position: relative;
    /* margin-top: 10%; */
    margin-bottom: 10%;
    height: 5vh;
    border-top: 1px solid black;
}
.container{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.footer__container{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer__container > h3{
    color: var(--secondary-black-text);
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
}
.social__links{
    display: flex;
    justify-content: space-between;
}