@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root{
  --primary-color: white;
  --secondary-black-text: #545b63;
  --background-black-color: #1f2225;
  --secondary-white-text: #e8eaed;
  --primary-text-white-color: white;
  --primary-text-black-color: #1a1d21;
}

body {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

button{
  font-family: "Poppins", sans-serif;
  font-weight: 500 !important;
}

@media only screen and (max-width: 768px) {
  h1, h2{
    font-size: 18px !important;
  }
  p{
    font-size: 12px !important;
  }
}

.container{
  width: 90%;
  height: 100%;
}